import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
	SimpleTableColumn,
	SimpleTableBodyRow,
	SimpleTableHeaderCellRoot,
	SimpleTableBodyCellRoot,
	VirtualTable,
} from '@compstak/ui-kit';
import { NoDataTab } from 'Components/Errors/NoDataMessaging';
import { createLoadingRows } from 'Components';

export type LeagueTableProps<T> = {
	columns: SimpleTableColumn<T>[];
	data: T[];
	id: string;
	isFetching: boolean;
	pageSize?: number;
	hasNextPage?: boolean;
	isFetchingNextPage?: boolean;
	fetchNextPage?: () => void;
};

const ROW_HEIGHT = 48;
const MAX_BODY_HEIGHT = ROW_HEIGHT * 10;

export const LeagueTable = <T,>({
	columns,
	data,
	id,
	isFetching,
	pageSize = 10,
	hasNextPage,
	isFetchingNextPage,
	fetchNextPage,
}: LeagueTableProps<T>) => {
	return (
		<TableWrap>
			<LeagueTableStyles />
			<BorderWrap>
				{!isFetching && data.length === 0 ? (
					<NoDataTab
						minHeight={`${MAX_BODY_HEIGHT}px`}
						message="Try removing some filters or expanding the area of your search"
					/>
				) : (
					<VirtualTable
						id={id}
						rows={
							isFetching && data.length === 0
								? createLoadingRows(pageSize)
								: data
						}
						columns={columns}
						maxBodyHeight={MAX_BODY_HEIGHT}
						bodyRowHeight={ROW_HEIGHT}
						onLoadMore={() => {
							if (hasNextPage && !isFetchingNextPage) {
								fetchNextPage?.();
							}
						}}
						isLoadingMore={isFetching || isFetchingNextPage}
					/>
				)}
			</BorderWrap>
		</TableWrap>
	);
};

export const BorderWrap = styled.div`
	border: ${({ theme }) => `1px solid ${theme.colors.gray.gray300}`};
	border-radius: 3px;
	overflow: hidden;
`;

export const TableWrap = styled.div`
	${SimpleTableBodyRow}:nth-child(odd) ${SimpleTableBodyCellRoot} {
		background-color: ${({ theme }) => theme.colors.neutral.n10};
	}
	${SimpleTableHeaderCellRoot}, ${SimpleTableBodyCellRoot} {
		padding: 0.5rem;
		font-size: 0.875rem;
	}
`;

const LeagueTableStyles = createGlobalStyle`
	${SimpleTableHeaderCellRoot}, ${SimpleTableBodyCellRoot} {
		justify-content: flex-end;
		&:nth-child(1) {
			justify-content: center;
		}
		&:nth-child(2) {
			justify-content: flex-start;
		}
		&:last-child {
			padding-right: 1em;
		}
	}
`;
