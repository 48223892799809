import { Button } from '@compstak/ui-kit';
import { useCompFeedbackMutation } from 'api';
import { useToast } from 'providers/ToastProvider';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { LeaseComp, SalesComp } from 'types/comp';
import { trimString } from 'util/trimString';

export type SuggestUpdateFormProps = {
	setFormIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	formIsOpen: boolean;
	selectedComps: SalesComp[] | LeaseComp[];
	compType: 'sale' | 'lease';
	market: string;
	className?: string;
};

export const SuggestUpdateForm = ({
	formIsOpen,
	setFormIsOpen,
	selectedComps,
	compType,
	market,
	className,
}: SuggestUpdateFormProps) => {
	const [suggestedUpdate, setSuggestedUpdate] = useState('');
	const sectionRef = useRef<HTMLElement | null>(null);

	const { addToast, removeToast } = useToast();

	const { mutateAsync: sendCompsFeedback } = useCompFeedbackMutation();

	const sendFeedback = async (compId: number) => {
		const toast = addToast({ title: 'Sending corrections...' });

		try {
			await sendCompsFeedback({
				compId,
				market,
				suggestion: suggestedUpdate,
				dataType: compType,
			});
			removeToast(toast.id);
			addToast({ title: 'Correction sent.' });
		} catch (error) {
			removeToast(toast.id);
			addToast({ title: 'There was an error sending this correction.' });
		}
	};

	useEffect(() => {
		if (!selectedComps.length && formIsOpen) setFormIsOpen(false);
	}, [selectedComps, formIsOpen]);

	useEffect(() => {
		if (formIsOpen)
			sectionRef?.current?.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
	}, [sectionRef, formIsOpen]);

	return (
		<StyledSourceCountContainer className={className}>
			<SuggestUpdateContainer>
				<SuggestUpdateText>
					What is missing or incorrect in this comp?
				</SuggestUpdateText>
				<SuggestUpdateFormContainer
					onSubmit={(e) => {
						e.preventDefault();
						if (!trimString(suggestedUpdate)) {
							addToast({ title: 'Please enter a reason first' });
						} else {
							selectedComps.forEach((comp) => {
								sendFeedback(comp.id);
							});
							setFormIsOpen(false);
						}
					}}
				>
					<StyledTextInput
						value={suggestedUpdate}
						onChange={(e) => {
							setSuggestedUpdate(e.target.value);
						}}
						placeholder={
							'Please include what information you would like to update...'
						}
					/>
					{/* @ts-expect-error TS2769: No overload matches this call.... */}
					<SuggestUpdateControls ref={sectionRef}>
						<CancelButton
							onClick={() => {
								setFormIsOpen(false);
							}}
						>
							Cancel
						</CancelButton>
						<Button type="submit">Send update</Button>
					</SuggestUpdateControls>
				</SuggestUpdateFormContainer>
			</SuggestUpdateContainer>
		</StyledSourceCountContainer>
	);
};

const StyledSourceCountContainer = styled.div`
	display: flex;
	padding: 16px 40px;
	font-size: 14px;
	line-height: 14px;
	font-weight: 400;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	align-items: center;
	color: ${({ theme }) => theme.colors.gray.n100};
	background-color: ${({ theme }) => theme.colors.gray.gray200};
`;

const StyledTextInput = styled.textarea`
	width: 540px;
	height: 150px;
	border-radius: 4px;
	border: none;
	margin-bottom: 10px;
`;

const SuggestUpdateFormContainer = styled.form`
	padding: 0;
`;

const SuggestUpdateContainer = styled.div`
	display: flex;
	gap: 24px;
	margin: 0 auto;
`;

const SuggestUpdateText = styled.span`
	font-size: 18px;
	line-height: 27px;
`;

const SuggestUpdateControls = styled.div`
	width: 100%;
	display: flex;
	gap: 5px;
	& button {
		width: 100%;
	}
`;

const CancelButton = styled(Button)`
	background-color: ${({ theme }) => theme.colors.gray.gray100};
	color: ${({ theme }) => theme.colors.gray.n100};
	&:not(:disabled) {
		&:hover {
			background-color: ${({ theme }) => theme.colors.gray.gray300};
		}
	}
`;
