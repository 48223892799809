import { useState } from 'react';
import { Flex } from '@compstak/ui-kit';
import { SuggestUpdateForm } from 'Components/SuggestUpdate';
import styled from 'styled-components';
import { SuggestUpdateButton } from 'Components/SuggestUpdate/SuggestUpdateButton';
import { SalesComp } from 'types';

type Props = {
	data: SalesComp;
};

export const SourceCountFooter = ({ data }: Props) => {
	const [formIsOpen, setFormIsOpen] = useState(false);

	const { versions } = data;

	const sourceCountText =
		versions === 1
			? 'This sale data was received from one source'
			: `This sale data was received from ${versions} different sources`;

	return (
		<StyledSourceCountContainer>
			{formIsOpen ? (
				<FormContainer>
					<SuggestUpdateForm
						formIsOpen={formIsOpen}
						setFormIsOpen={setFormIsOpen}
						selectedComps={[data]}
						compType="sale"
						market={data.market}
					/>
				</FormContainer>
			) : (
				<>
					<Flex>
						<SourceCount>{versions}</SourceCount>
						<SourceCountText>{sourceCountText}</SourceCountText>
					</Flex>
					<SuggestUpdateButton
						onClick={() => {
							setFormIsOpen(true);
						}}
					/>
				</>
			)}
		</StyledSourceCountContainer>
	);
};

const FormContainer = styled.div`
	width: 100%;
`;

const StyledSourceCountContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 16px 40px;
	font-size: 14px;
	line-height: 14px;
	font-weight: 400;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	align-items: center;
	color: ${({ theme }) => theme.colors.gray.n100};
`;

const SourceCount = styled.div`
	width: 32px;
	height: 32px;
	color: ${({ theme }) => theme.colors.white.white};
	background-color: ${({ theme }) => theme.colors.neutral.n100};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const SourceCountText = styled.span`
	margin-left: 8px;
`;
