import { useMemo } from 'react';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { FilterDescription } from 'Components/Filters/Base/Filter/FilterDescription';
import { useFilterFieldContext } from '../FilterFieldContextProvider';
import { LeaseFilters } from 'models/filters/lease';
import { ChoiceSwitcher } from 'Components/Filters/Base/ChoiceSwitcher';

const LIMITED_DETAILS_ATTRIBUTES: Record<
	keyof Pick<LeaseFilters, 'hasFullOrLimitedDetails' | 'hasLimitedDetails'>,
	string
> = {
	hasFullOrLimitedDetails: 'Include comps missing one required field',
	hasLimitedDetails: 'Show only comps missing one required field',
} as const;

export const LimitedDetailsFilter = () => {
	const { filters } = useFilterFieldContext();

	const attribute = useMemo(
		() =>
			Object.typedKeys(LIMITED_DETAILS_ATTRIBUTES).find(
				(attr) => filters[attr]
			) ?? 'hasFullOrLimitedDetails',
		[filters]
	);

	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<>
					<ChoiceSwitcher
						{...props}
						attribute={'hasFullOrLimitedDetails'}
						filter={filters.hasFullOrLimitedDetails}
						options={[
							{
								title: LIMITED_DETAILS_ATTRIBUTES['hasFullOrLimitedDetails'],
								value: 'true',
							},
						]}
						onFilterChange={(changes) => {
							props.onFilterChange({
								...changes,
								hasLimitedDetails: null,
							});
						}}
					/>
					<ChoiceSwitcher
						{...props}
						attribute={'hasLimitedDetails'}
						filter={filters.hasLimitedDetails}
						options={[
							{
								title: LIMITED_DETAILS_ATTRIBUTES['hasLimitedDetails'],
								value: 'true',
							},
						]}
						onFilterChange={(changes) => {
							props.onFilterChange({
								...changes,
								hasFullOrLimitedDetails: null,
							});
						}}
					/>
					<FilterDescription>
						Required fields include: Tenant Name, Transaction SqFt, Rent Info,
						Date Info
					</FilterDescription>
				</>
			)}
		</FilterBase>
	);
};
