import { ReactNode } from 'react';
import { Spinner } from '@compstak/ui-kit';
import { NoDataTabCompact } from 'Components/Errors/NoDataMessaging';
import { ChartContainer } from 'PortfolioAnalytics/styles/PortfolioUI';
import { CHARTS_NO_DATA_COMPONENT_HEIGHT } from 'PortfolioAnalytics/constants';
import { ErrorAlert } from '../../Components/Errors/ErrorAlert';

interface BenchmarkingChartStateWrapperProps {
	isError?: boolean;
	isFetching?: boolean;
	noData?: boolean;
	showNoDataMessage?: boolean;
	noDataMessage?: string;
	children: ReactNode;
}

export const ChartStateWrapper = ({
	isError = false,
	isFetching = false,
	noData = false,
	showNoDataMessage = false,
	noDataMessage,
	children,
}: BenchmarkingChartStateWrapperProps) => {
	if (isError) {
		return (
			<ChartContainer>
				<ErrorAlert subject="chart" />
			</ChartContainer>
		);
	}

	if (isFetching) {
		return (
			<ChartContainer>
				<Spinner size="l" />
			</ChartContainer>
		);
	}

	if (noData) {
		return (
			<ChartContainer>
				<NoDataTabCompact
					minHeight={CHARTS_NO_DATA_COMPONENT_HEIGHT}
					message={showNoDataMessage && noDataMessage}
				/>
			</ChartContainer>
		);
	}

	return <>{children}</>;
};
