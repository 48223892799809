import { SearchTable, SearchTableProps } from 'Components';
import {
	LeaseAndSaleAveragesResponse,
	SEARCH_INFINITE_PAGE_SIZE,
	useSearchFilterParams,
	useSearchLeasesInfiniteQuery,
	useSearchTableAverages,
} from 'api';
import { useLeasesSalesAveragesFormatting } from 'hooks/useLeasesSalesAveragesFormatting';
import { useSearchTableSelection } from 'hooks/useSearchTableSelection';
import { filtersToQueryString } from 'models/filters/util';
import { useFilters } from 'reducers/filtersReducer';
import { routes } from 'router';
import { LeaseComp } from 'types';
import { useInfiniteTableRows } from '../../../utils/useInfiniteTableRows';
import { useSearchTableSort } from '../useSearchTableSort';
import { ExportCompsButton } from 'Components/Export/ExportCompsButton';
import { UnlockCompButton } from 'Components/Unlock/UnlockCompButton';
import {
	getCompWithLimitedDetailsCellBgColor,
	getDefaultCellBackgroundColor,
} from 'Components/SearchTable/utils';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useIsExchange } from 'hooks';

type Props = Partial<SearchTableProps<LeaseComp>>;

export const LeasesSearchTable = (props: Props) => {
	const [filters] = useFilters();

	const params = useSearchFilterParams({ filters });

	const {
		data: leasesData,
		isFetching,
		fetchNextPage,
		hasNextPage,
	} = useSearchLeasesInfiniteQuery(params);

	const totalCount = leasesData?.pages[0].totalCount ?? 0;
	const searchAverages = leasesData?.pages[0].averages;

	const rows = useInfiniteTableRows({
		data: leasesData,
		getRows: (page) => page.comps,
		isFetching,
		pageSize: SEARCH_INFINITE_PAGE_SIZE,
	});

	const { data: averages } = useSearchTableAverages({
		compType: 'lease',
		searchAverages,
		filters,
	});

	const averagesFormatting = useLeasesSalesAveragesFormatting({
		averages: averages as LeaseAndSaleAveragesResponse,
		filters,
	});

	const selectionProps = useSearchTableSelection({
		compType: 'lease',
		totalCount,
		filters,
	});

	const sortProps = useSearchTableSort();

	const flags = useFeatureFlags();
	const isExchange = useIsExchange();

	return (
		<SearchTable
			{...props}
			{...selectionProps}
			{...sortProps}
			rows={rows}
			averages={averagesFormatting}
			getRowId={(row) => row.id}
			noData={!isFetching && totalCount === 0}
			onLoadMore={() => {
				if (hasNextPage && !isFetching) {
					fetchNextPage();
				}
			}}
			columnPreferencesType="lease"
			renderActionButton={(row) =>
				row.own ? (
					<ExportCompsButton
						compType="lease"
						compIds={[row.id]}
						buttonType="wide-row"
					/>
				) : (
					<UnlockCompButton type="lease" comp={row} buttonType="wide-row" />
				)
			}
			getBodyCellProps={(row, rowIndex) => ({
				to: routes.leaseById.toHref({ id: row.id }),
				state: {
					index: rowIndex,
					queryString: filtersToQueryString(filters),
				},
			})}
			getCellBgColor={
				flags['PartialExposurePhase1'] && !isExchange
					? (row) =>
							row.hasLimitedDetails
								? getCompWithLimitedDetailsCellBgColor
								: getDefaultCellBackgroundColor
					: undefined
			}
		/>
	);
};
