import { calendarUtils, Flex, TooltipV2 } from '@compstak/ui-kit';
import { APP_HEADER_HEIGHT } from 'constants/sizes';
import { useModal } from 'providers/ModalProvider';
import styled from 'styled-components';
import { PromotionMarketsDialog } from './PromotionMarketsDialog';
import { Promotion } from './promotionTypes';
import { useMemo } from 'react';
import { BetaChip } from 'Components';
import { formatCsv, formatMoney } from 'format';
import IconQuestionMark from 'ui/svg_icons/question_mark_outline.svg';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { PromotionRewards, LegacyRewardsLabel } from './promotionRewards';

export const PromoPageOutline = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	inset: ${APP_HEADER_HEIGHT}px 0 0 0;
	overflow: auto;
`;

export const PROMO_TABS_MAX_WIDTH = 1600;

function formatPromoDate(date: string) {
	if (!date) {
		return 'N/A';
	}
	return calendarUtils.getDate(date, 'fullDate2');
}

export function PromoTitleAndDate({ promotion }: { promotion: Promotion }) {
	return (
		<Flex gap="0.5rem" style={{ flexWrap: 'wrap' }}>
			<Flex gap="0.5rem" style={{ flexWrap: 'wrap' }}>
				<PromoTitle>{promotion.title}</PromoTitle>
				<BetaChip>
					{promotion.compType === 'Leases' ? 'Lease Comps' : 'Sale Comps'}
				</BetaChip>
			</Flex>
			<div>
				{formatPromoDate(promotion.marketingStartDate)} -{' '}
				{formatPromoDate(promotion.marketingEndDate)}
			</div>
		</Flex>
	);
}

export const PromoDetailsSection = ({
	promotion,
}: {
	promotion: Promotion;
}) => {
	const flags = useFeatureFlags();
	const { 'AppsPromotionIterations3.5': appsPromotionIterations35FF } = flags;

	const { openModal } = useModal();

	const sortedMarkets = useMemo(() => {
		return promotion.markets.toSorted((a, b) =>
			a.displayName.localeCompare(b.displayName)
		);
	}, [promotion.markets]);

	const marketNames = sortedMarkets.map((m) => m.displayName);

	const submarketByMarkets = useMemo(() => {
		if (
			!flags['AppsPromotionIterations3.5'] ||
			!promotion.submarkets ||
			promotion.submarketsDisplayText ||
			promotion.markets.length === 1
		)
			return null;
		return promotion.submarkets.reduce(
			(acc, submarket) => {
				if (!acc[submarket.marketId]) {
					acc[submarket.marketId] = {
						submarkets: [submarket.name],
					};
				} else acc[submarket.marketId].submarkets.push(submarket.name);
				return acc;
			},
			{} as Record<string, { submarkets: string[] }>
		);
	}, [promotion, flags]);

	return (
		<PromoDetailsContainer>
			<GridStyled>
				{promotion.compType === 'Leases' && (
					<>
						<div>
							<BoldLabel>Space Types</BoldLabel>
							<div>{formatCsv(promotion.spaceTypes)}</div>
						</div>
						<>
							{promotion.propertyTypes != null &&
								promotion.propertyTypes.length > 0 && (
									<div>
										<BoldLabel>Property Type</BoldLabel>
										<div>{formatCsv(promotion.propertyTypes)}</div>
									</div>
								)}
							{promotion.propertySubtypes != null &&
								promotion.propertySubtypes.length > 0 && (
									<div>
										<BoldLabel>Property Subtype</BoldLabel>
										<div>{formatCsv(promotion.propertySubtypes)}</div>
									</div>
								)}
						</>
						{promotion.executionDateLookback && (
							<div>
								<BoldLabel>Lease Executed In</BoldLabel>
								<div>Last {promotion.executionDateLookback.slice(1)} days</div>
							</div>
						)}
						{!promotion.executionDateLookback && (
							<div>
								<BoldLabel>Execution Date</BoldLabel>
								<div>
									Since {formatPromoDate(promotion.marketingExecutionStartDate)}
								</div>
							</div>
						)}
						<div>
							<BoldLabel>Rewards</BoldLabel>
							{appsPromotionIterations35FF ? (
								<PromotionRewards promotion={promotion} />
							) : (
								<LegacyRewardsLabel promotion={promotion} />
							)}
						</div>
						{!flags['AppsPromotionIterations3.5'] &&
							promotion.rewardOnlyMasterCreator && (
								<div>
									<BoldLabelAndTooltip>
										Primary or Versions
										<TooltipV2 content="A primary version is the first version that CompStak receives for a Lease or Sales Comp">
											<span>
												<StyledIconQuestionMark />
											</span>
										</TooltipV2>
									</BoldLabelAndTooltip>
									<div>Primary Versions Only</div>
								</div>
							)}
					</>
				)}
				{promotion.compType === 'Sales' && (
					<>
						{promotion.saleDateLookback && (
							<div>
								<BoldLabel>Sale Executed In</BoldLabel>
								<div>Last {promotion.saleDateLookback.slice(1)} days</div>
							</div>
						)}
						{!promotion.saleDateLookback && (
							<div>
								<BoldLabel>Sale Date</BoldLabel>
								<div>
									Since {formatPromoDate(promotion.marketingSaleStartDate)}
								</div>
							</div>
						)}
						<div>
							<BoldLabel>Sale price</BoldLabel>
							<div>
								Over {formatMoney(promotion.salePrice, { shorten: false })}
							</div>
						</div>

						<div>
							<BoldLabel>Rewards</BoldLabel>
							{appsPromotionIterations35FF ? (
								<PromotionRewards promotion={promotion} />
							) : (
								<LegacyRewardsLabel promotion={promotion} />
							)}
						</div>
						<div>
							<BoldLabel>Property Type</BoldLabel>
							<div>{formatCsv(promotion.propertyTypes)}</div>
						</div>
						{promotion.propertySubtypes != null &&
							promotion.propertySubtypes.length > 0 && (
								<div>
									<BoldLabel>Property Subtype</BoldLabel>
									<div>{formatCsv(promotion.propertySubtypes)}</div>
								</div>
							)}
					</>
				)}
				{(promotion.compType === 'Sales' ||
					flags['AppsPromotionIterations3.5']) &&
					promotion.premiumOnly && (
						<div>
							<BoldLabelAndTooltip>
								Premium or Full
								<TooltipV2
									content={
										promotion.compType === 'Leases'
											? TOOLTIP_PREMIUM.LEASES
											: TOOLTIP_PREMIUM.SALES
									}
								>
									<span>
										<StyledIconQuestionMark />
									</span>
								</TooltipV2>
							</BoldLabelAndTooltip>
							<div>Premium</div>
						</div>
					)}
				{flags['AppsPromotionIterations3.5'] &&
					promotion.rewardOnlyMasterCreator && (
						<div>
							<BoldLabelAndTooltip>
								Primary or Versions
								<TooltipV2 content="A primary version is the first version that CompStak receives for a Lease or Sales Comp">
									<span>
										<StyledIconQuestionMark />
									</span>
								</TooltipV2>
							</BoldLabelAndTooltip>
							<div>Primary Versions Only</div>
						</div>
					)}
			</GridStyled>
			<div>
				<BoldLabel>Markets</BoldLabel>
				{promotion.marketsDisplayText && promotion.markets.length > 10 ? (
					<div>
						{promotion.marketsDisplayText} (
						<SeeMore
							onClick={() => {
								openModal({
									modalContent: (
										<PromotionMarketsDialog
											promotionTitle={promotion.title}
											promotionMarkets={marketNames}
										/>
									),
								});
							}}
						>
							See List of Markets
						</SeeMore>
						)
					</div>
				) : (
					<div>{promotion.marketsDisplayText ?? formatCsv(marketNames)}</div>
				)}
			</div>
			{flags['AppsPromotionIterations3.5'] &&
				promotion.submarkets &&
				promotion.submarkets.length > 0 && (
					<div>
						<BoldLabel>Submarkets</BoldLabel>
						{submarketByMarkets === null ? (
							<div>
								{promotion.submarketsDisplayText ??
									formatCsv(
										promotion.submarkets
											.map((s) => s.name)
											.toSorted((a, b) => a.localeCompare(b))
									)}
							</div>
						) : (
							<SubmarketList>
								{sortedMarkets.map((m, i) => (
									<li key={'submarkets' + i}>
										{m.displayName}:{' '}
										{formatCsv(
											submarketByMarkets[m.marketId].submarkets.toSorted(
												(a, b) => a.localeCompare(b)
											)
										)}
									</li>
								))}
							</SubmarketList>
						)}
					</div>
				)}
		</PromoDetailsContainer>
	);
};

const TOOLTIP_PREMIUM = {
	SALES:
		'A premium comp must include at least 2 of the fields below: Cap Rate, NOI / NOI PSF, OpEx / OpEx PSF',
	LEASES:
		'A premium comp must include the following 3 fields: TI value / Work value (PSF) (e.g. $43), Free rent, Transaction type (e.g. renewal)',
};

const PromoTitle = styled.h4`
	text-transform: uppercase;
`;

const PromoDetailsContainer = styled(Flex)`
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	width: 100%;
`;

const GridStyled = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	width: 100%;
	gap: 8px;
`;

const BoldLabel = styled.b`
	font-size: 16px;
	font-weight: 600;
`;

export const PromoSimpleWarning = styled.div`
	margin: 16px;
`;

const SeeMore = styled.button`
	all: unset;
	color: ${(p) => p.theme.colors.blue.blue400};
	text-decoration: underline;
	cursor: pointer;
`;

const BoldLabelAndTooltip = styled(BoldLabel)`
	display: flex;
	gap: 4px;
	align-items: center;
`;

const StyledIconQuestionMark = styled(IconQuestionMark)`
	width: 1rem;
	height: 1rem;
`;

const SubmarketList = styled.ul`
	list-style-type: disc;
	list-style-position: inside;
`;
