import { Promotion } from './promotionTypes';

type PromotionRewardsProps = {
	promotion: Promotion;
};

export const PromotionRewards = ({ promotion }: PromotionRewardsProps) => {
	const creditRewardMultiplier = promotion.creditReward?.multiplier ?? 0;
	const { tiers, bonusTier } = promotion.giftCardReward ?? {};
	const { rewardDollars } = bonusTier ?? {};
	const hasCreditReward = creditRewardMultiplier !== 0;
	const hasGiftcardReward =
		(Array.isArray(tiers) && tiers.length > 0) || Boolean(bonusTier);

	const hasBonuseTier = Boolean(rewardDollars);
	const hasTiers = tiers && tiers.length > 0;
	const bonusTierLabel = hasBonuseTier ? `${rewardDollars}$ per comp` : '';
	const giftCardLabel = hasTiers
		? hasBonuseTier
			? `Gift Cards and ${bonusTierLabel} after submitting ${tiers.length} comps`
			: 'Gift Cards'
		: '';
	const creditsLabel = hasCreditReward
		? `${creditRewardMultiplier}x Credits`
		: '';

	if (hasCreditReward && hasGiftcardReward) {
		return <div>{`${creditsLabel} + ${giftCardLabel}`}</div>;
	}

	if (hasGiftcardReward) {
		return <div>{giftCardLabel}</div>;
	}

	if (hasCreditReward) {
		return <div>{creditsLabel}</div>;
	}

	return <div>N/A</div>;
};

export const LegacyRewardsLabel = ({ promotion }: PromotionRewardsProps) => {
	const creditRewardMultiplier = promotion.creditReward?.multiplier ?? 0;
	const giftcardTiers = promotion.giftCardReward?.tiers ?? [];
	const hasCreditReward = creditRewardMultiplier !== 0;
	const hasGiftcardReward =
		Array.isArray(giftcardTiers) && giftcardTiers.length > 0;
	const creditsLabel = `${creditRewardMultiplier}x Credits`;
	const giftCardLabel = 'Gift Card';

	if (hasCreditReward && hasGiftcardReward) {
		return <div>{`${creditsLabel} + ${giftCardLabel}`}</div>;
	}

	if (hasCreditReward) {
		return <div>{creditsLabel}</div>;
	}

	if (hasGiftcardReward) {
		<div>{giftCardLabel}</div>;
	}

	return <div>'N/A'</div>;
};
