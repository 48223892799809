import React from 'react';

import CompPartial from './CompPartial';
import styles from '../styles/details.less';
import button from 'ui/styles/button.less';
import { CompType, LeaseComp } from 'types';

type CompVerifyState = any;

type CompVerifyProps = {
	compType: CompType;
};

export default class CompVerify extends React.Component<
	CompVerifyProps,
	CompVerifyState
> {
	constructor(props: CompVerifyProps) {
		super(props);

		this.state = {
			compSuggestion: '',
		};
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleCompSuggestionInput(event) {
		this.setState({
			compSuggestion: event.target.value,
		});
	}

	sendCompSuggestion() {
		if (this.state.compSuggestion.trim() === '') {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'feedbackActions' does not exist on type ... Remove this comment to see the full error message
			this.props.feedbackActions.addFeedback('Please enter a reason first');
		} else {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			const id = this.props.comp.id;
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'pageActions' does not exist on type 'Rea... Remove this comment to see the full error message
			this.props.pageActions.sendCompSuggestion(
				id,
				this.state.compSuggestion,
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
				this.props.comp.market,
				this.props.compType
			);
		}
	}

	verifyButton() {
		if (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'compVerificationSent' does not exist on ... Remove this comment to see the full error message
			!this.props.compVerificationSent &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			this.props.user.userType === 'exchange' &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			this.props.comp.own === true
		) {
			return (
				<div
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'pageActions' does not exist on type 'Rea... Remove this comment to see the full error message
					onClick={this.props.pageActions.showVerifyComp}
					className={`${button.button} ${styles.buttonTiny} ${styles.buttonVerify}`}
				>
					Suggest an Update
				</div>
			);
		} else if (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'compVerificationSent' does not exist on ... Remove this comment to see the full error message
			!this.props.compVerificationSent &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			this.props.user.userType !== 'exchange'
		) {
			return (
				<div
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'pageActions' does not exist on type 'Rea... Remove this comment to see the full error message
					onClick={this.props.pageActions.showVerifyComp}
					className={`${button.button} ${styles.buttonTiny} ${styles.buttonVerify}`}
				>
					Suggest an Update
				</div>
			);
		} else {
			return false;
		}
	}

	verifyText() {
		const { compType } = this.props;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'appConfig' does not exist on type 'Reado... Remove this comment to see the full error message
		if (this.props.appConfig.isExchange) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			if (this.props.comp.versions === 1) {
				return (
					<p>
						CompStak user has verified this{' '}
						<span className={styles.hideAtSmall}>{compType}</span> data
					</p>
				);
			} else {
				return (
					<p>
						CompStak users have verified this{' '}
						<span className={styles.hideAtSmall}>{compType}</span> data
					</p>
				);
			}
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
		} else if (this.props.comp.versions === 1) {
			return <p>This {compType} data was received from one source</p>;
		} else {
			return (
				<p>
					This {compType} data was received from{' '}
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
					<span className={styles.versions}> {this.props.comp.versions} </span>{' '}
					different sources
				</p>
			);
		}
	}

	formSendText() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'appConfig' does not exist on type 'Reado... Remove this comment to see the full error message
		if (this.props.appConfig.isExchange) {
			return 'Send Correction';
		} else {
			return 'Send Update';
		}
	}

	formPlaceholder() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'appConfig' does not exist on type 'Reado... Remove this comment to see the full error message
		if (this.props.appConfig.isExchange) {
			return 'Please include what information you believe is incorrect...';
		} else {
			return 'Please include what information you would like to update...';
		}
	}

	renderCompPartial(comp: LeaseComp) {
		const verifyButton = this.verifyButton();
		return (
			<CompPartial {...this.props} verifyButton={verifyButton} lease={comp} />
		);
	}

	renderCompVerify() {
		const verifyButton = this.verifyButton();
		const verifyText = this.verifyText();

		return (
			<div className={this.className()}>
				<div className={styles.leftSide}>
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
					<span className={styles.number}>{this.props.comp.versions}</span>
					{verifyText}
				</div>
				<div className={styles.rightSide}>{verifyButton}</div>
			</div>
		);
	}

	className() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
		return this.props.comp.partial
			? styles.partialMessage
			: styles.verification;
	}

	render() {
		const formSendText = this.formSendText();
		const formPlaceholder = this.formPlaceholder();

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'verifyComp' does not exist on type 'Read... Remove this comment to see the full error message
		if (this.props.verifyComp) {
			return (
				<div className={this.className() + ' ' + styles.expanded}>
					<div className={styles.leftSide}>
						<h3>What is missing or incorrect in this comp?</h3>
					</div>
					<div className={styles.rightSide}>
						<textarea
							value={this.state.compSuggestion}
							onChange={this.handleCompSuggestionInput.bind(this)}
							className={styles.leaseSuggestion}
							placeholder={formPlaceholder}
						/>
						<div className={styles.buttonContainer}>
							<span
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'pageActions' does not exist on type 'Rea... Remove this comment to see the full error message
								onClick={this.props.pageActions.hideVerifyComp}
								className={button.gray}
							>
								Cancel
							</span>
							<span
								onClick={this.sendCompSuggestion.bind(this)}
								className={button.blue}
							>
								{formSendText}
							</span>
						</div>
					</div>
				</div>
			);
		} else {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			const { comp } = this.props;

			return comp.partial
				? this.renderCompPartial(comp)
				: this.renderCompVerify();
		}
	}
}
