import styled from 'styled-components';

export const NoPortfolios = () => {
	return (
		<Container>
			<GrayLabel>CREATE A PORTFOLIO</GrayLabel>
			<Body>
				<Title>Create a Portfolio</Title>
				<Text>
					Organizing properties into portfolios can be a useful way to view and
					analyze property data. To create a portfolio,{' '}
					<strong>click the New Portfolio button on the upper left.</strong>
				</Text>
			</Body>
		</Container>
	);
};

const Container = styled.div`
	max-width: 600px;
	padding: 3rem;
`;

const GrayLabel = styled.div`
	font-size: 0.75rem;
	color: ${(p) => p.theme.colors.neutral.n80};
`;

const Body = styled.div`
	padding-top: 1rem;
	padding-bottom: 1.5rem;
`;

const Title = styled.div`
	font-size: 1.75rem;
	color: ${(p) => p.theme.colors.gray.gray700};
	font-weight: 300;
	line-height: 1.5;
	margin-bottom: 1.25rem;
	letter-spacing: 0.05em;
`;

const Text = styled.div`
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.05em;
	font-weight: 300;
	color: ${(p) => p.theme.colors.gray.gray700};
`;
