import styled from 'styled-components';
import IconAlertInCircle from 'ui/svg_icons/alert_in_circle.svg';
import { colors } from '@compstak/ui-kit';

const { gray700, n100 } = colors.gray;

type Props = {
	subject?: string;
};

export const ErrorAlert = ({ subject: _subject }: Props) => {
	const subject = _subject ? ` ${_subject}` : '';

	return (
		<Root>
			<IconAlertInCircle />
			<Title>Unable to load{subject}</Title>
			<Message>An error occurred while loading{subject}.</Message>
			<Message>Please refresh the page to try again.</Message>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.5em;
	padding: 5em 0;
	height: 100%;
	width: 100%;
`;

const Title = styled.div`
	font-size: 1.25em;
	color: ${gray700};
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
`;

const Message = styled.div`
	font-size: 1em;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
	color: ${n100};
`;
