import { Flex, VirtualTable } from '@compstak/ui-kit';
import { ExportCompsButton } from 'Components/Export';
import { NoDataTab } from 'Components/Errors/NoDataMessaging';
import { ListMapToggler } from 'PortfolioAnalytics/Components/ListMapToggler';
import { RemovePropertiesButton } from 'PortfolioAnalytics/Components/RemovePropertiesButton';
import { PortfolioOverviewSectionHeader } from 'PortfolioAnalytics/Dashboard/UI';
import { PortfolioMap } from 'PortfolioAnalytics/PortfolioMap';
import { usePortfolioProperties } from 'PortfolioAnalytics/Properties/PortfolioPropertiesProvider';
import { usePortfolioPropertiesColumns } from 'PortfolioAnalytics/Properties/usePortfolioPropertiesColumns';
import { MAX_BODY_HEIGHT, ROW_HEIGHT } from 'PortfolioAnalytics/constants';
import { APP_HEADER_HEIGHT } from 'constants/sizes';
import { useState } from 'react';
import { routes } from 'router';
import styled from 'styled-components';

export const PropertiesTab = () => {
	const {
		data: rows,
		isFetchingNextPage,
		isFetching,
		hasNextPage,
		fetchNextPage,
		selectionState,
		portfolio,
	} = usePortfolioProperties();
	const portfolioPropertiesColumns = usePortfolioPropertiesColumns();

	const [view, setView] = useState<'list' | 'map'>('list');

	return (
		<Container>
			<Flex>
				<Title>Properties</Title>
				<ListMapToggler view={view} onViewClick={(view) => setView(view)} />
			</Flex>
			{view === 'list' && (
				<TableContainer>
					<TableContentContainer>
						{rows.length === 0 && (
							<NoDataTab
								minHeight={`${MAX_BODY_HEIGHT}px`}
								message="Try removing some filters or expanding the area of your search"
							/>
						)}
						{rows.length > 0 && (
							<VirtualTable
								id="properties"
								rows={rows}
								columns={portfolioPropertiesColumns}
								maxBodyHeight={MAX_BODY_HEIGHT}
								bodyRowHeight={ROW_HEIGHT}
								headerHeight={ROW_HEIGHT}
								isBodyHoverable
								getBodyCellProps={({ row }) => ({
									onClick: () => {
										window.open(
											routes.propertyById.toHref({ propertyId: row.id }),
											'_blank'
										);
									},
								})}
								onLoadMore={() => {
									if (hasNextPage && !isFetchingNextPage) {
										fetchNextPage();
									}
								}}
								isLoadingMore={isFetching || isFetchingNextPage}
							/>
						)}
					</TableContentContainer>
					<Flex justifyContent="flex-start" gap="1rem">
						<ExportCompsButton
							buttonType="table"
							compType="property"
							compIds={selectionState.selection}
							getComps={selectionState.getSelectedRows}
						/>
						<RemovePropertiesButton
							portfolio={portfolio}
							selectionState={selectionState}
						/>
					</Flex>
				</TableContainer>
			)}
			{view === 'map' && (
				<MapContainer>
					<PortfolioMap portfolioId={portfolio.portfolio.id} isMapView={true} />
				</MapContainer>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const Title = styled(PortfolioOverviewSectionHeader)`
	margin-top: 0;
`;

const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const TableContentContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.white.white};
	border: 1px solid ${(p) => p.theme.colors.gray.gray100};
`;

const MapContainer = styled.div`
	height: calc(100vh - ${3 * APP_HEADER_HEIGHT}px);
`;
